import React from "react";
import { motion } from "framer-motion";
import emailIcon from "../../assets/email.png";
import linkedinIcon from "../../assets/linkedin.png";

const Contact = () => {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <div className="contact-wrapper">
      <section id="contact">
        <motion.div {...fadeInUp}>
          <p className="section__text__p1">Get in Touch</p>
          <h1 className="title">Contact Me</h1>
        </motion.div>

        <motion.div
          className="contact-info-upper-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <motion.div
            className="contact-info-container"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img
              src={emailIcon}
              alt="Email"
              className="icon contact-icon email-icon"
            />
            <p>
              <motion.a
                href="mailto:sapkotaaayush.workmail@gmail.com"
                whileHover={{ color: "#00fff5" }}
              >
                sapkotaaayush.workmail@gmail.com
              </motion.a>
            </p>
          </motion.div>

          <motion.div
            className="contact-info-container"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img
              src={linkedinIcon}
              alt="LinkedIn"
              className="icon contact-icon"
            />
            <p>
              <motion.a
                href="https://www.linkedin.com/in/aayush-sapkota-243655246/"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ color: "#00fff5" }}
              >
                LinkedIn
              </motion.a>
            </p>
          </motion.div>
        </motion.div>
      </section>
    </div>
  );
};

export default Contact;
