import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ivyProject from "../../assets/ivy.png";
import hwInfoProject from "../../assets/HWInfo.png";
import tictactoeProject from "../../assets/tictactoe.jpg";
import arrowIcon from "../../assets/arrow.png";

const Projects = () => {
  const navigate = useNavigate();

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <div className="projects-wrapper">
      <section id="projects">
        <motion.div {...fadeInUp}>
          <p className="section__text__p1">Browse My Recent</p>
          <h1 className="title">Projects</h1>
        </motion.div>

        <div className="experience-details-container">
          <div className="about-containers">
            <motion.div
              className="details-container color-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <div className="article-container">
                <img
                  src={ivyProject}
                  alt="Futsal Management System"
                  className="project-img"
                />
              </div>
              <h2 className="experience-sub-title project-title">
                Futsal Management System
              </h2>
              <div className="btn-container">
                <motion.button
                  className="btn btn-color-2 project-btn"
                  onClick={() =>
                    (window.location.href = "https://github.com/extremex7")
                  }
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Github
                </motion.button>
              </div>
            </motion.div>

            <motion.div
              className="details-container color-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <div className="article-container">
                <img
                  src={hwInfoProject}
                  alt="Hardware Info App"
                  className="project-img"
                />
              </div>
              <h2 className="experience-sub-title project-title">
                Hardware Info React Native App
              </h2>
              <div className="btn-container">
                <motion.button
                  className="btn btn-color-2 project-btn"
                  onClick={() =>
                    (window.location.href =
                      "https://github.com/extremex7/MobileInformationApp")
                  }
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Github
                </motion.button>
              </div>
            </motion.div>

            <motion.div
              className="details-container color-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <div className="article-container">
                <img
                  src={tictactoeProject}
                  alt="Tic Tac Toe Game"
                  className="project-img"
                />
              </div>
              <h2 className="experience-sub-title project-title">
                Tic-Tac-Toe
              </h2>
              <div className="btn-container">
                <motion.button
                  className="btn btn-color-2 project-btn"
                  onClick={() =>
                    (window.location.href = "https://github.com/extremex7")
                  }
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Github
                </motion.button>
                <motion.button
                  className="btn btn-color-2 project-btn"
                  onClick={() => navigate("/tictactoe")}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Live Demo
                </motion.button>
              </div>
            </motion.div>
          </div>
        </div>

        <motion.img
          src={arrowIcon}
          alt="Arrow"
          className="icon arrow"
          onClick={() => (window.location.href = "/#contact")}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        />
      </section>
    </div>
  );
};

export default Projects;
