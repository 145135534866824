import React, { useState, useEffect } from "react";
import BackButton from "./BackButton";
import "./TicTacToe.css";
import { IoReloadOutline } from "react-icons/io5";
import { BsPlayFill } from "react-icons/bs";

const TicTacToe = () => {
  const [board, setBoard] = useState(Array(9).fill(""));
  const [isPlayerO, setIsPlayerO] = useState(true);
  const [winner, setWinner] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getCellSize = () => {
    if (windowWidth <= 480)
      return { width: "90px", height: "90px", fontSize: "2.5rem" };
    if (windowWidth <= 768)
      return { width: "110px", height: "110px", fontSize: "3rem" };
    return { width: "130px", height: "130px", fontSize: "3.5rem" };
  };

  const winConditions = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  const handleClick = (index) => {
    if (board[index] || winner) return;
    const newBoard = [...board];
    newBoard[index] = isPlayerO ? "O" : "X";
    setBoard(newBoard);
    setIsPlayerO(!isPlayerO);
    checkWinner(newBoard);
  };

  const checkWinner = (currentBoard) => {
    for (let condition of winConditions) {
      const [a, b, c] = condition;
      if (
        currentBoard[a] &&
        currentBoard[a] === currentBoard[b] &&
        currentBoard[a] === currentBoard[c]
      ) {
        setWinner(currentBoard[a]);
        return;
      }
    }
    if (currentBoard.every((cell) => cell)) {
      setWinner("Draw");
    }
  };

  const resetGame = () => {
    setBoard(Array(9).fill(""));
    setIsPlayerO(true);
    setWinner(null);
  };

  const cellStyle = getCellSize();

  return (
    <div className="game-container">
      <BackButton />
      <h1 className="game-title">Tic Tac Toe</h1>

      <div className="player-turn">
        {!winner && <p>Player {isPlayerO ? "O" : "X"}'s Turn</p>}
      </div>

      {winner && (
        <div className="winner-message">
          <p>{winner === "Draw" ? "It's a Draw!" : `Player ${winner} Wins!`}</p>
          <button className="new-game-btn" onClick={resetGame}>
            <BsPlayFill /> New Game
          </button>
        </div>
      )}

      <div className="game-board">
        {board.map((cell, index) => (
          <button
            key={index}
            className={`cell ${cell} ${cell && "filled"}`}
            onClick={() => handleClick(index)}
            disabled={cell || winner}
            style={cellStyle}
          >
            {cell}
          </button>
        ))}
      </div>

      {!winner && (
        <button className="reset-btn" onClick={resetGame}>
          <IoReloadOutline /> Reset Game
        </button>
      )}
    </div>
  );
};

export default TicTacToe;
