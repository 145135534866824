import React from "react";
import profilePic from "../../assets/profile-pic.png";
import linkedinIcon from "../../assets/linkedin.png";
import githubIcon from "../../assets/github.png";

const Profile = () => {
  return (
    <section id="profile">
      <div className="section__pic-container">
        <img src={profilePic} alt="Aayush Sapkota" />
      </div>
      <div className="section__text">
        <p className="section__text__p1">Hello, I'm</p>
        <h1 className="title">Aayush Sapkota</h1>
        <p className="section__text__p2">Frontend Developer</p>
        <div className="btn-container">
          <button
            className="btn btn-color-2"
            onClick={() => window.open("/assets/CVMain.pdf")}
          >
            Download CV
          </button>
          <button
            className="btn btn-color-1"
            onClick={() => (window.location.href = "/#contact")}
          >
            Contact Info
          </button>
        </div>
        <div id="socials-container">
          <img
            src={linkedinIcon}
            alt="LinkedIn"
            className="icon"
            onClick={() =>
              (window.location.href =
                "https://www.linkedin.com/in/aayush-sapkota-243655246/")
            }
          />
          <img
            src={githubIcon}
            alt="Github"
            className="icon"
            onClick={() =>
              (window.location.href = "https://github.com/extremex7")
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Profile;
