import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DesktopNav from "./components/Navbar/DesktopNav";
import HamburgerNav from "./components/Navbar/HamburgerNav";
import Profile from "./components/Profile/Profile";
import About from "./components/About/About";
import Experience from "./components/Experience/Experience";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import TicTacToe from "./components/Projects/TicTacToe/TicTacToe";
import "./styles/style.css";
import "./styles/mediaqueries.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <DesktopNav />
              <HamburgerNav />
              <Profile />
              <About />
              <Experience />
              <Projects />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route path="/tictactoe" element={<TicTacToe />} />
      </Routes>
    </Router>
  );
}

export default App;
