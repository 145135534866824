import React from "react";
import checkmarkIcon from "../../assets/checkmark.png";
import arrowIcon from "../../assets/arrow.png";
import { motion } from "framer-motion";

const Experience = () => {
  const getColorForPercentage = (percentage) => {
    if (percentage <= 20) return "#FF4C4C";
    if (percentage <= 40) return "#FF9F40";
    if (percentage <= 60) return "#FFCD56";
    if (percentage <= 80) return "#4BC0C0";
    return "#36A2EB";
  };

  const skillSets = {
    frontend: [
      { name: "HTML", level: "Intermediate", percentage: 82 },
      { name: "CSS", level: "Intermediate", percentage: 80 },
      { name: "JavaScript", level: "Intermediate", percentage: 75 },
      { name: "React", level: "Basic", percentage: 60 },
    ],
    backend: [
      { name: "Java", level: "Basic", percentage: 47 },
      { name: "Node", level: "Basic", percentage: 60 },
    ],
    others: [
      { name: "MySQL", level: "Basic", percentage: 40 },
      { name: "Quality Assurance", level: "Basic", percentage: 35 },
      { name: "Git", level: "Intermediate", percentage: 65 },
    ],
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  const SkillBar = ({ skill }) => (
    <motion.article
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
      className="skill-item"
    >
      <img src={checkmarkIcon} alt="Checkmark" className="icon" />
      <div className="skill-info">
        <div className="skill-header">
          <h3>{skill.name}</h3>
          <span className="skill-percentage">{skill.percentage}%</span>
        </div>
        <div className="progress-bar-container">
          <motion.div
            className="progress-bar"
            initial={{ width: 0 }}
            animate={{ width: `${skill.percentage}%` }}
            transition={{ duration: 1, delay: 0.2 }}
            style={{
              backgroundColor: getColorForPercentage(skill.percentage),
            }}
          />
        </div>
      </div>
    </motion.article>
  );

  return (
    <div className="experience-wrapper">
      <section id="experience">
        <motion.div {...fadeInUp}>
          <p className="section__text__p1">Explore My</p>
          <h1 className="title">Experience</h1>
        </motion.div>

        <div className="experience-details-container">
          <div className="about-containers">
            {Object.entries(skillSets).map(([category, skills], index) => (
              <motion.div
                key={category}
                className="details-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 * index }}
              >
                <h2 className="experience-sub-title">
                  {category.charAt(0).toUpperCase() + category.slice(1)}{" "}
                  Development
                </h2>
                <div className="article-container">
                  {skills.map((skill, idx) => (
                    <SkillBar key={skill.name} skill={skill} />
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <motion.img
          src={arrowIcon}
          alt="Arrow"
          className="icon arrow"
          onClick={() => (window.location.href = "/#projects")}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        />
      </section>
    </div>
  );
};

export default Experience;
