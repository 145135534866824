import React, { useState, useEffect } from "react";

const DesktopNav = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("profile");

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);

      // Get current section
      const sections = [
        "profile",
        "about",
        "experience",
        "projects",
        "contact",
      ];
      const current = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (current) setActiveSection(current);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav id="desktop-nav" className={scrolled ? "scrolled" : ""}>
      <div className="logo">Aayush Sapkota</div>
      <div>
        <ul className="nav-links">
          {["about", "experience", "projects", "contact"].map((item) => (
            <li key={item}>
              <a
                href={`#${item}`}
                className={activeSection === item ? "active" : ""}
              >
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default DesktopNav;
