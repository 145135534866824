import React from "react";
import aboutPic from "../../assets/about-pic.png";
import experienceIcon from "../../assets/experience.png";
import educationIcon from "../../assets/education.png";
import arrowIcon from "../../assets/arrow.png";
import { motion } from "framer-motion";

const About = () => {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <div className="about-wrapper">
      <section id="about">
        <motion.div {...fadeInUp}>
          <p className="section__text__p1">Get To Know More</p>
          <h1 className="title">About Me</h1>
        </motion.div>

        <div className="section-container">
          <motion.div
            className="section__pic-container"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <img src={aboutPic} alt="Aayush working" className="about-pic" />
          </motion.div>

          <div className="about-details-container">
            <div className="about-containers">
              <motion.div
                className="details-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <img src={experienceIcon} alt="Experience" className="icon" />
                <h3>Experience</h3>
                <p>
                  Fresher <br />
                  Frontend Development
                </p>
              </motion.div>

              <motion.div
                className="details-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
              >
                <img src={educationIcon} alt="Education" className="icon" />
                <h3>Education</h3>
                <p>
                  Bachelors in Information Management
                  <br />
                </p>
              </motion.div>
            </div>

            <motion.div
              className="text-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.8 }}
            >
              <p>
                I am a final semester student of BIM, currently self learning
                Frontend Development and my preferred stack is MERN. I have
                worked on my college project based on PHP and have basic
                concepts about web development using PHP. I also have some basic
                core knowledge about Java, and my next step will be to focus
                learning and working in MERN Stack. Quality Assurance is another
                subject that intrigues me and am knowledged about Manual Testing
                techniques and Automation Testing would be my next target to
                learn about.
              </p>
            </motion.div>
          </div>
        </div>

        <motion.img
          src={arrowIcon}
          alt="Arrow"
          className="icon arrow"
          onClick={() => (window.location.href = "/#experience")}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        />
      </section>
    </div>
  );
};

export default About;
