import React, { useState, useEffect } from "react";

const HamburgerNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? "hidden" : "auto";
  };

  return (
    <nav id="hamburger-nav" className={scrolled ? "scrolled" : ""}>
      <div className="logo">Aayush Sapkota</div>
      <div className="hamburger-menu">
        <div
          className={`hamburger-icon ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`menu-links ${isOpen ? "open" : ""}`}>
          {["about", "experience", "projects", "contact"].map((item) => (
            <li key={item}>
              <a href={`#${item}`} onClick={toggleMenu}>
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </a>
            </li>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default HamburgerNav;
